import { Link } from 'gatsby'
import React from 'react'
import Layout from "components/layout"
import Meta from "components/meta"

const aboutus = () => (
   
    <Layout>
        <Meta
      title="ABOUT US"
      desc="自己紹介ページ"
    />

        <>
        <h1>about us</h1>
        <ul>
        <li>Birth : 1997/12/21</li>
        <li>大学生n年生(in 京都)</li>
        <li>📍 大阪</li>
        </ul>
        <h2>興味とスキル</h2>
        <h3>音楽</h3>
        <div>
        <p>ミキシング・マスタリング・レコーディング 勉強中 / 機材が好き。エフェクターが好き / エレキギター・エレキベース弾きます / 曲作りとかは、特にしていないです。できない。</p>
        <p>使っているDAW : Studio One(メイン) , Logic</p>
        </div>
        <h3>写真</h3>
        <div><p>主にライブ写真。動画も興味あり。YouTuberみたいなのしてみたい。</p></div>
        <h3>デザインなど</h3>
        <div><p>Web(HTML,CSS,PHPなど勉強中) / WordPress / Adobe Premiere Pro / Adobe Illustrator / Adobe Photoshop / Adobe InDesign</p></div>
        </>
    </Layout>

    
)

export default aboutus